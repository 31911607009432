export const colors = [
    "#cbd4db",
    "#E8384F",
    "#FD612C",
    "#FD9A00",
    "#EEC300",
    "#A4CF30",
    "#62D26F",
    "#37C5AB",
    "#20AAEA",
    "#4186E0",
    "#7A6FF0",
    "#AA62E3",
    "#E362E3",
    "#EA4E9D",
    "#FC91AD",
    "#8DA3A6",
];

export const iconNames = [
    "radio_button_checked",
    "whatshot",
    "poll",
    "emoji_objects",
    "fireplace",
    "storefront",
    "spa",
    "event_note",
    "money",
    "terrain",
    "tag_faces",
    "flash_on",
    "security",
    "folder",
    "format_quote",
    "weekend",
    "send",
    "archive",
    "next_week",
    "chat",
    "track_changes",
    "pets",
    "store",
    "extension",
    "done_outline",
    "fitness_center",
    "beenhere",
    "assessment",
];
