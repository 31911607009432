import React from "react";
import "./TeamFilterbar.css";


const TeamFilterbar = () => {
    
  return (
    <div className="TeamFilterbar">
      
      <button >
        Overview
      </button>
      
    </div>
  );
};

export default TeamFilterbar;
