import app from "firebase/app";
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDo9C1Uci4h3zf6Y1uqz2dWl6yw-QHQJqI",
    authDomain: "agilo-demo.firebaseapp.com",
    projectId: "agilo-demo",
    storageBucket: "agilo-demo.appspot.com",
    messagingSenderId: "507623122719",
    appId: "1:507623122719:web:f471da46f8a662d096b22a",
    measurementId: "G-26QHQSY07B"
};

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
    }

    doCreateUserWithEmailAndPassword = (email, password) => {
        return this.auth.createUserWithEmailAndPassword(email, password);
    };

    doSignInWithEmailAndPassword = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    };

    doSignInAnonymously = () => {
        return this.auth.signInAnonymously();
    };

    doSignOut = () => {
        return this.auth.signOut();
    };

    doCreatePermanentAccountFromAnonymous(email, password) {
        const credential = firebase.auth.EmailAuthProvider.credential(
            email,
            password
        );
        return this.auth.currentUser.linkWithCredential(credential);
    }

    doPasswordReset = (email) => {
        this.auth.sendPasswordResetEmail(email);
    };

    doPasswordUpdate = (password) => {
        this.auth.currentUser.updatePassword(password);
    };
}

const myFirebase = new Firebase();

export default myFirebase;
